import { lauderLarken as lauderlarkinaxios } from "@/plugins/axios";
import lauderlarkin_storage from "../storage";
import router from "@/router";
import { handleLauderLarkinRequest } from "@/errors";
// const version  =   "19";
export default {
  async lauderlogout({ commit, getters, dispatch }, failreq = false) {
    try {
      lauderlarkinaxios.cancel();
      if (!failreq && lauderlarkin_storage.getToken()) {
        await lauderlarkinaxios.logout();
      }
      // await commit("laudersetPaymentData", []);
      // await commit("laudersetAssociationsData", []);
      // commit("laudersetUserProfile", {});
      commit("luadersetLoginState", false);
      commit("luaderSetMgmt", false);
      commit("lauderSetSelectedAss", false);
      // await commit("laudersetProfileFetTime", null);
      // await commit("laudersetAssociationTime", false);
      // await commit("luedarSetPayTen", []);
      // await commit("luedarSetDash", {});
      // await commit("luedarSetPayState", {});
      lauderlarkin_storage.setToken(null);
      // await lauderlarkin_storage.setPaymentData(null, getters.laudergetdb);
      // await lauderlarkin_storage.setAssociationsData(null, getters.laudergetdb);
      lauderlarkin_storage.emptyInfos(getters.laudergetdb);
      lauderlarkin_storage.emptyClients(getters.laudergetdb);
      lauderlarkin_storage.emptyPayStat(getters.laudergetdb);
      lauderlarkin_storage.emptyPayTen(getters.laudergetdb);
      lauderlarkin_storage.emptyReports(getters.laudergetdb);
      lauderlarkin_storage.emptyAssStat(getters.laudergetdb);
      lauderlarkin_storage.emptyPayStat(getters.laudergetdb);
      lauderlarkin_storage.emptyPayments(getters.laudergetdb);
      lauderlarkin_storage.emptyAssociations(getters.laudergetdb);
      lauderlarkin_storage.emptyContacts(getters.laudergetdb);
      // await lauderlarkin_storage.setAllReports(null, getters.laudergetdb);
      // lauderlarkin_storage.setClosedPay(null);
      // lauderlarkin_storage.setClosedAss(null);
      lauderlarkin_storage.setSession(null);
      lauderlarkin_storage.setProfile(null);
      lauderlarkin_storage.setPayFetTime(null);
      lauderlarkin_storage.setAssFetTime(null);
      lauderlarkin_storage.setMgmt(false);
      lauderlarkin_storage.setSelectedAss(null);
      if (!router.currentRoute.path.includes("login"))
        await router.push({ name: "lauderLaw---Login" });
    } catch (e) {
      dispatch("lauderlogout", true);
    }
  },
  async lauderstartProject({ commit, dispatch }) {
    try {
      await commit("lauderStarted");
      await commit("luadersetLoginState", lauderlarkin_storage.getToken());
      // const ObjectStores = [
      //   {
      //     key:payments_key,
      //     params:{
      //       keyPath: "token"
      //     }
      //   }
      // ]
      // const read = await dispatch("startStorage",{
      //   name:'lauder',
      //   version,
      //   ObjectStores = [],
      //   onUpgrade = () => {},
      // });
      // console.log(read);
      let ready = await lauderlarkin_storage.startStorage();
      console.log("end_storage");
      commit("lauderSetDb", ready.main_db);
      if (lauderlarkin_storage.getToken()) {
        commit("luaderSetMgmt", lauderlarkin_storage.getMgmt());
        if (lauderlarkin_storage.getSelectedAss()) {
          await commit(
            "lauderSetSelectedAss",
            lauderlarkin_storage.getSelectedAss()
          );
        } else {
          await commit("lauderSetSelectedAss", {
            Client_Associations: "All Associations",
            AllAss: true
          });
        }
      } else {
        dispatch("lauderlogout");
      }
    } catch (e) {
      console.log(e);
    }
  },
  async lauderFetchUserData({ commit, dispatch }) {
    try {
      let result = await lauderlarkinaxios.getProfile();
      let data = handleLauderLarkinRequest(result);
      if (!data) return false;
      let user = data[0];
      commit("laudersetUserProfile", user);
      lauderlarkin_storage.setProfile(user);
      return true;
    } catch (e) {
      await dispatch("lauderFetchUserData");
    }
  }
};
