const app_key = "app",
  payments_key = "payments",
  associations_key = "associations",
  profileKey = "profile",
  mgmt_key = "is_mgmt",
  session_key = "login_session",
  ass_fet = "associations_Fetched",
  pay_fet = "payments_fetched",
  info_key = "information_key",
  asss_key = "selected_ass",
  payStat = "payStats_db",
  payTen = "payTen_db",
  allReport = "AllReports",
  contacts_db = "Contacts",
  clientsNames = "ClientsNames",
  accepted = "Accepted",
  ass_Stat = "AssStat",
  version = "18";
const setData = async (key, data, pinnedDb) => {
  try {
    let request = false;
    let db = pinnedDb || main_db;
    if (data && typeof data === typeof []) {
      let transaction = db.transaction([key], "readwrite");
      let objectStore = transaction.objectStore(key);
      for (const dat of data) {
        request = await objectStore.add(
          { data: JSON.stringify(dat) },
          dat.Client_Associations
        );
      }
    } else {
      let transaction = db.transaction([key], "readwrite");
      let objectStore = transaction.objectStore(key);
      objectStore.clear();
    }
    return request;
  } catch (e) {
    console.log(e);
    return false;
  }
};
const getKeyValue = async (token, db, key, val_key, only = true) => {
  if (!token) return false;
  return new Promise((resolve, reject) => {
    let transaction = db.transaction([key], "readonly");
    let objectStore = transaction.objectStore(key);
    let request = objectStore.get(token);
    request.onerror = err => reject(err);
    request.onsuccess = evt => {
      //console.log(evt,token)
      if (evt.target.result) {
        if (only) {
          resolve(evt.target.result[val_key]);
        } else {
          if (Array.isArray(evt.target.result[val_key])) {
            resolve({
              results: evt.target.result[val_key],
              ...evt.target.result
            });
          } else {
            resolve({
              ...evt.target.result[val_key],
              addedAt: evt.target.result.addedAt
            });
          }
        }
      } else {
        resolve(false);
      }
    };
  });
};
const setKeyValue = async (token, db, key, val_key, data) => {
  let transaction = db.transaction([key], "readwrite");
  let objectStore = transaction.objectStore(key);
  objectStore.delete(token);
  let obj = { addedAt: Date.now() };
  obj[val_key] = data;
  let request = await objectStore.put(obj, token);
  await new Promise((resolve, reject) => {
    request.onerror = err => reject(err);
    transaction.oncomplete = resolve();
  });
};
const clearObjStore = async (key, db) => {
  let transaction = db.transaction([key], "readwrite");
  let objectStore = transaction.objectStore(key);
  objectStore.clear();
};
const prepareTransaction = async (db = main_db, tranName) => {
  return new Promise((resolve, reject) => {
    try {
      let x = db
        .transaction(tranName)
        .objectStore(tranName)
        .getAll();
      x.onsuccess = e => resolve(e.target.result);
      x.onerror = e => {
        throw new Error(e);
      };
    } catch (e) {
      reject(e);
    }
  });
};
const getAllByKey = async (db = main_db, key, func = r => r) => {
  let IndexedPayments = await prepareTransaction(db, key),
    payments = [];
  IndexedPayments.forEach((payment, index) => {
    let data = func(payment);
    data.id = index + 1;
    payments.push(data);
  });
  return payments;
};
// window.indexedDB =
//   window.indexedDB ||
//   window.mozIndexedDB ||
//   window.webkitIndexedDB ||
//   window.msIndexedDB;
// window.IDBTransaction = window.IDBTransaction ||
//   window.webkitIDBTransaction ||
//   window.msIDBTransaction || { READ_WRITE: "readwrite" }; // This line should only be needed if it is needed to support the object's constants for older browsers
// window.IDBKeyRange =
//   window.IDBKeyRange || window.webkitIDBKeyRange || window.msIDBKeyRange;
let main_db;
let storage = {
  async CreateObjectStoreIfNotFound(request, db, key, params, indexes = []) {
    try {
      const dbNames = db.objectStoreNames;
      let store;
      if (dbNames.contains(key)) {
        db.deleteObjectStore(key);
      }
      store = db.createObjectStore(key);
      const desiredKeyPathForMyIndex = params.keyPath;
      if (store.indexNames.contains(desiredKeyPathForMyIndex)) {
        const myIndex = store.index(desiredKeyPathForMyIndex);
        const currentKeyPath = myIndex.keyPath;
        if (currentKeyPath !== desiredKeyPathForMyIndex) {
          store.deleteIndex(desiredKeyPathForMyIndex);
          store.createIndex(desiredKeyPathForMyIndex, desiredKeyPathForMyIndex);
        }
      } else {
        store.createIndex(desiredKeyPathForMyIndex, desiredKeyPathForMyIndex);
      }
    } catch (e) {
      let re = db.createObjectStore(key, params);
      if (indexes && indexes.length > 0) {
        for (const index of indexes) {
          await re.createIndex(
            index.name,
            index.key || index.name,
            index.options || { unique: false }
          );
        }
      } else {
        await re.createIndex("data", "data", { unique: false });
      }
      return re;
    }
  },
  async startStorage() {
    return new Promise((resolve, reject) => {
      try {
        let request = window.indexedDB.open("lauder", version);
        request.onerror = err => {
          console.log(err);
          reject(err);
        };
        request.onupgradeneeded = async e => {
          main_db = e.target.result;
          storage.setToken(null);
          console.log(main_db);
          await this.CreateObjectStoreIfNotFound(
            request,
            main_db,
            payments_key,
            {
              keyPath: "token"
            }
          );
          await this.CreateObjectStoreIfNotFound(
            request,
            main_db,
            associations_key,
            {
              keyPath: "token"
            }
          );
          await this.CreateObjectStoreIfNotFound(
            request,
            main_db,
            clientsNames,
            {
              keyPath: "id",
              autoIncrement: true
            }
          );
          await this.CreateObjectStoreIfNotFound(request, main_db, allReport, {
            keyPath: "token"
          });
          await this.CreateObjectStoreIfNotFound(request, main_db, ass_Stat, {
            keyPath: "token"
          });
          await this.CreateObjectStoreIfNotFound(request, main_db, info_key, {
            keyPath: "token"
          });
          await this.CreateObjectStoreIfNotFound(request, main_db, payStat, {
            keyPath: "token"
          });
          await this.CreateObjectStoreIfNotFound(request, main_db, payTen, {
            keyPath: "token"
          });
          await this.CreateObjectStoreIfNotFound(
            request,
            main_db,
            contacts_db,
            {
              keyPath: "token"
            }
          );
          e.target.transaction.oncomplete = () => {
            resolve({
              main_db
              //payments_transaction
            });
          };
          e.target.transaction.onerror = e => {
            console.log(e);
            reject(e);
          };
        };
        request.onsuccess = () => {
          resolve({
            main_db: request.result
          });
        };
      } catch (e) {
        console.log(e);
        reject(false);
      }
    });
  },
  async getInformationData(token, db = main_db) {
    return await getKeyValue(token, db, info_key, "info");
  },
  async setInformationData(info, token, db = main_db) {
    await setKeyValue(token, db, info_key, "info", info);
  },
  async getPayStatData(token, db = main_db, only = true) {
    return await getKeyValue(token, db, payStat, "payStats", only);
  },
  async setPayStatData(payStats, token, db = main_db) {
    return await setKeyValue(token, db, payStat, "payStats", payStats);
  },
  async getAssStatData(token, db = main_db, only = true) {
    return await getKeyValue(token, db, ass_Stat, "assStats", only);
  },
  async setAssStatData(assStats, token, db = main_db) {
    return await setKeyValue(token, db, ass_Stat, "assStats", assStats);
  },
  async getPayTenData(token, db = main_db, only = true) {
    return await getKeyValue(token, db, payTen, "PTEN", only);
  },
  async setPayTenData(PTEN, token, db = main_db) {
    return await setKeyValue(token, db, payTen, "PTEN", PTEN);
  },
  async getContactsData(token, db = main_db, only = true) {
    return await getKeyValue(token, db, contacts_db, "contacts", only);
  },
  async setContactsData(contacts, token, db = main_db) {
    return await setKeyValue(token, db, contacts_db, "contacts", contacts);
  },
  async emptyInfos(db = main_db) {
    await clearObjStore(info_key, db);
  },
  async emptyPayStat(db = main_db) {
    await clearObjStore(payStat, db);
  },
  async emptyPayTen(db = main_db) {
    await clearObjStore(payTen, db);
  },
  async emptyClients(db = main_db) {
    await clearObjStore(clientsNames, db);
  },
  async emptyReports(db = main_db) {
    await clearObjStore(allReport, db);
  },
  async emptyContacts(db = main_db) {
    await clearObjStore(contacts_db, db);
  },
  async emptyAssStat(db = main_db) {
    await clearObjStore(ass_Stat, db);
  },
  async emptyAssociations(db = main_db) {
    await clearObjStore(associations_key, db);
  },
  async emptyPayments(db = main_db) {
    await clearObjStore(payments_key, db);
  },
  async getCertainReports(token, db = main_db, only = true) {
    return await getKeyValue(token, db, allReport, "asses", only);
  },
  async setCertainReports(asses, token, db = main_db) {
    return await setKeyValue(token, db, allReport, "asses", asses);
  },
  async getCertainAssociations(token, db = main_db, only = true) {
    return await getKeyValue(token, db, associations_key, "asses", only);
  },
  async setCertainAssociations(asses, token, db = main_db) {
    return await setKeyValue(token, db, associations_key, "asses", asses);
  },
  async getCertainPayments(token, db = main_db, only = true) {
    return await getKeyValue(token, db, payments_key, "payments", only);
  },
  async setCertainPayments(payments, token, db = main_db) {
    return await setKeyValue(token, db, payments_key, "payments", payments);
  },
  async getAllAssociations(db = main_db) {
    return await getAllByKey(db, associations_key);
  },
  async getAllReports(db = main_db) {
    return await getAllByKey(db, allReport);
  },
  async getAllPayments(db = main_db) {
    return await getAllByKey(db, payments_key);
  },
  async getAllClients(db = main_db) {
    return await getAllByKey(db, clientsNames, payment =>
      JSON.parse(payment.data)
    );
  },
  async setAllClients(data, db = main_db) {
    this.request = setData(clientsNames, data, db) || this.request;
  },
  getToken() {
    return localStorage.getItem(app_key) || null;
  },
  setToken(token) {
    if (token) {
      localStorage.setItem(app_key, token);
    } else {
      localStorage.removeItem(app_key);
    }
  },
  getMgmt() {
    return localStorage.getItem(mgmt_key) || null;
  },
  setMgmt(res) {
    if (res) {
      localStorage.setItem(mgmt_key, res);
    } else {
      localStorage.removeItem(mgmt_key);
    }
  },
  setProfile(data) {
    if (data) {
      localStorage.setItem(profileKey, JSON.stringify(data));
    } else {
      localStorage.removeItem(profileKey);
    }
  },
  getProfile() {
    return JSON.parse(localStorage.getItem(profileKey) || "{}");
  },
  getSelectedAss() {
    return JSON.parse(localStorage.getItem(asss_key) || "{}");
  },
  setSelectedAss(s) {
    localStorage.setItem(asss_key, JSON.stringify(s));
  },
  getSession() {
    return localStorage.getItem(session_key) || null;
  },
  setSession(date) {
    if (date) {
      localStorage.setItem(session_key, date);
    } else {
      localStorage.removeItem(session_key);
    }
  },
  setAccepted(val) {
    if (val) {
      localStorage.setItem(accepted, val);
    } else {
      localStorage.removeItem(accepted);
    }
  },
  getAccepted() {
    localStorage.getItem(accepted);
  },
  setAssFetTime() {
    localStorage.setItem(ass_fet, `${new Date().getTime()}`);
  },
  getAssFetTime() {
    return localStorage.getItem(ass_fet);
  },
  setPayFetTime() {
    localStorage.setItem(pay_fet, `${new Date().getTime()}`);
  },
  getPayFetTime() {
    return localStorage.getItem(pay_fet);
  }
};
export default storage;
